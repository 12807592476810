import React, { useEffect } from "react";
// import { toast } from "react-toastify";

function App() {
  // const [mind, setMind] = useState("");
  // const [showCalendar, setSetshowCalendar] = useState(false);

  // const noBtn = useRef<HTMLButtonElement>(null);
  // const [isListenerEnabled, setIsListenerEnabled] = useState(true);

  const sendTelegramMessage = (text: string) => {
    const url =
      "https://api.telegram.org/bot5649019408:AAGBxHAXtxrFtJIA3uj8fITLu3oNZyRA1FU/sendMessage";
    fetch(url, {
      body: JSON.stringify({
        chat_id: "1510573998",
        text,
      }),
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
    });
  };

  useEffect(() => {
    // if (isMobile || isMobileOnly || isMobileSafari) {
    //   return sendTelegramMessage("Rizka just opened the web on mobile");
    // }
    sendTelegramMessage("Rizka just opened the web");
  }, []);

  // useEffect(() => {
  //   if (!showCalendar) return;
  //   (async function () {
  //     const cal = await getCalApi();
  //     cal("ui", {
  //       styles: { branding: { brandColor: "#000000" } },
  //       hideEventTypeDetails: false,
  //       layout: "month_view",
  //     });
  //   })();
  // }, [showCalendar]);

  // useEffect(() => {
  //   if (!noBtn.current) return;
  //   const handleClick = (event: any) => {
  //     if (!isListenerEnabled) return;
  //     setIsListenerEnabled(false);
  //     setInterval(() => {
  //       if (!noBtn.current) return;
  //       const height = document.documentElement.clientHeight;
  //       const width = document.documentElement.clientWidth;
  //       let randY = Math.floor(Math.random() * height);
  //       let randX = Math.floor(Math.random() * width);
  //       noBtn.current.style.position = "absolute";
  //       noBtn.current.style.top = randY + "px";
  //       noBtn.current.style.right = randX + "px";
  //     }, 200);
  //   };

  //   const element = noBtn.current;

  //   element.addEventListener("mouseover", handleClick);
  //   console.log({ element });
  //   return () => {
  //     element.removeEventListener("mouseover", handleClick);
  //   };
  // }, [isListenerEnabled]);

  // if (isMobile || isMobileOnly || isMobileSafari) {
  //   return (
  //     <div className="grid v-screen place-items-center">
  //       <div style={{ height: "16px" }} />
  //       <p>Silahkan dibuka di laptop😂</p>
  //     </div>
  //   );
  // }

  // return (
  //   <>
  //     <div className="grid v-screen place-items-center">
  //       <div style={{ height: "16px" }} />
  //       <h3>Will you go out with me to watch this movie?</h3>
  //       <div style={{ height: "16px" }} />
  //       <div>
  //         <p>
  //           Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: Sunday, 19th
  //           February (kenapa minggu? karna sabtu ada bayang-bayang OT💀)
  //         </p>
  //         <p>Location &nbsp;: Grand Batam Mall</p>
  //       </div>
  //       <div style={{ height: "16px" }} />
  //       <img src={require("./assets/poster.jpg")} width="15%" alt="" />
  //       <div style={{ height: "16px" }} />
  //       <div style={{ display: "flex" }}>
  //         <button
  //           type="button"
  //           className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
  //           onClick={() => sendTelegramMessage("Yes Rey, I will")}
  //         >
  //           Yes
  //         </button>
  //         <button
  //           id="noBtn"
  //           ref={noBtn}
  //           type="button"
  //           className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
  //         >
  //           No
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    // <div className="grid v-screen place-items-center">
    //   <div style={{ height: "16px" }} />
    //   <h1>
    //     Currently no invitation is available. Submit what you have in mind
    //     below.
    //   </h1>
    //   <div style={{ height: "8px" }} />
    //   <div style={{ width: "20%" }}>
    //     <input
    //       type="text"
    //       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //       value={mind}
    //       onChange={(e) => setMind(e.target.value)}
    //     />
    //   </div>
    //   <div style={{ height: "8px" }} />
    //   <button
    //     type="submit"
    //     className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    //     onClick={() => {
    //       if (!mind) {
    //         toast.error("Isi dulu goblok", {
    //           position: "bottom-center",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "light",
    //         });
    //         return;
    //       }
    //       sendTelegramMessage(mind);
    //       setMind("");
    //       toast.success("Okie dokie", {
    //         position: "bottom-center",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //       });
    //     }}
    //   >
    //     Submit
    //   </button>
    // </div>
    <div className="grid v-screen h-screen place-items-center">
      <div>
        <h1 className="text-center mb-4 font-light text-2xl">
          I miss you a lot
        </h1>
        {/* <div>
          <button
            id="noBtn"
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-900"
            onClick={() => {
              setSetshowCalendar(true);
              sendTelegramMessage("Yes you may");
            }}
          >
            Yes you may
          </button>
          <button
            id="noBtn"
            type="button"
            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            onClick={() => {
              setSetshowCalendar(false);
              sendTelegramMessage("I don't know");
            }}
          >
            I don't know
          </button>
        </div> */}
      </div>

      {/* {showCalendar && (
        <h1 className="text-center mb-4 font-light text-2xl">pick the date</h1>
      )}
      {showCalendar && (
        <Cal
          calLink="reynandaptr/dinner"
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
      )} */}
    </div>
  );
}

export default App;
